import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import JsPDF from 'jspdf';
import QRCode from 'qrcode';
import dayjs from 'dayjs';
import { useParams, useSearchParams } from 'react-router-dom';
import { Col, Row, Result, Popover, Space } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import cx from 'classnames';
import { PrinterOutlined, PaperClipOutlined } from '@ant-design/icons';
import styles from './memberCertificate.module.css';
import { ReactComponent as Logo } from '../../assets/motionLogo.svg';
import { getUserCertificate } from '../../services/services';
import { ReactComponent as Signature } from '../../assets/motionSignature.svg';
import download from '../../assets/download.png';
import { CompanyInterface } from '../../models/companyInterface';
import config from '../../config';
import { UserCategory } from '../../consts/UserCategoryEnum';
import motionCertificateBack from '../../assets/motionCertBack.jpg';

const MemberCertificate = (): ReactElement => {
  const { t } = useTranslation('common');
  const [qr, setQr] = useState<string | null>(null);
  const [companyData, setCompanyData] = useState<CompanyInterface>();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  useEffect(() => {
    const setCompany = async (id: string): Promise<void> => {
      const userCertificate = await getUserCertificate(id);
      if (userCertificate === undefined) return;
      if (userCertificate?.registrationDate !== undefined)
        userCertificate.registrationDate = dayjs(
          userCertificate.registrationDate,
          config.dateFormat.universalFormat,
        ).format('DD.MM.YYYY');
      if (userCertificate?.membershipDue !== undefined)
        userCertificate.membershipDue = dayjs(
          userCertificate.membershipDue,
          config.dateFormat.universalFormat,
        ).format('DD.MM.YYYY');
      setCompanyData(userCertificate);
    };
    if (id !== undefined) setCompany(id);
  }, [id]);

  const handleDownload = async (scale: number): Promise<void> => {
    const doc = new JsPDF('l', 'mm', 'a4');
    const elementHTML = document.querySelector('#pdfContent') as HTMLElement;

    const pdfWidth = 297; // A4 width in mm
    const canvas = await html2canvas(elementHTML, {
      scale,
      useCORS: true,
    });

    const imgData = canvas.toDataURL('image/png');
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    const ratio = contentHeight / contentWidth;

    const a4Height = pdfWidth * ratio;

    doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, a4Height);
    doc.save('member-certificate.pdf');
  };

  useEffect(() => {
    QRCode.toDataURL(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }${config.routes.memberCertificate.replace(':id', id as string)}?status=1`,
    )
      .then((url) => {
        setQr(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  const isValid =
    companyData &&
    dayjs(companyData?.membershipDue, 'DD.MM.YYYY').format('YYYY.MM.DD') >=
      dayjs().format('YYYY.MM.DD');

  const longCompanyName = useMemo(() => {
    if (companyData?.companyName && companyData?.companyName?.length > 75) return true;
    return false;
  }, [companyData?.companyName]);

  return (
    <div className={styles.container}>
      {status === '1' ? (
        companyData && (
          <Result
            status={isValid ? 'success' : 'warning'}
            title={t(`misc.${isValid ? 'valid' : 'notValid'}CertificateMessage`)
              .replace(':company', companyData?.companyName)
              .replace(
                ':date',
                dayjs(companyData?.membershipDue, 'DD.MM.YYYY').format('YYYY.MM.DD'),
              )}
          />
        )
      ) : (
        <>
          <Row justify={'end'} className={styles.downloadBtnWrap}>
            <Col>
              <Popover
                content={
                  <Space direction='vertical' className={styles.popoverDownload}>
                    <button
                      onClick={() => {
                        handleDownload(2);
                      }}
                      className={styles.download}
                    >
                      <Row justify={'space-around'} align='middle' gutter={5}>
                        <Col>
                          <PaperClipOutlined />
                        </Col>
                        <Col>{t('misc.webQuality')}</Col>
                      </Row>
                    </button>
                    <button
                      onClick={() => {
                        handleDownload(4);
                      }}
                      className={styles.download}
                    >
                      <Row justify={'space-around'} align='middle' gutter={5}>
                        <Col>
                          <PrinterOutlined />
                        </Col>
                        <Col>{t('misc.printQuality')}</Col>
                      </Row>
                    </button>
                  </Space>
                }
                trigger='click'
                placement='bottomRight'
              >
                <button className={styles.download}>
                  <Row justify={'space-around'} align='middle' gutter={5}>
                    <Col>
                      <img src={download} className={styles.downloadImg} alt='' />
                    </Col>
                    <Col>{t('misc.downloadCertificate')}</Col>
                  </Row>
                </button>
              </Popover>
            </Col>
          </Row>
          <div className={styles.pdfContent} id='pdfContent'>
            <div className={styles.content}>
              <Row justify='space-between'>
                <Col className={styles.title}>
                  {companyData?.userCategory === UserCategory.MEMBER
                    ? t('misc.membership')
                    : companyData?.userCategory === UserCategory.PARTNER &&
                      t('misc.partnership')}{' '}
                  {t('misc.certificate')}
                </Col>
              </Row>
              <Row>
                <Col span={24} className={styles.text}>
                  <Trans
                    i18nKey='common:certificateMotion.description'
                    components={[
                      <div
                        className={cx(styles.companyName, longCompanyName && styles.longName)}
                        key={0}
                      />,
                    ]}
                    values={{
                      companyName: companyData?.companyName,
                      userType: companyData?.userCategory,
                      country: companyData?.country?.toUpperCase(),
                    }}
                  />

                  <Row className={styles.period} justify='space-between' align='middle'>
                    <Col className={styles.dateText}>
                      {t('misc.issueDate')}:
                      <br />
                      <b>{companyData?.registrationDate as string}</b>
                    </Col>
                    <Col className={styles.dateText}>
                      {t('misc.validTill')}:
                      <br />
                      <b>{companyData?.membershipDue as string}</b>
                    </Col>
                  </Row>
                  <Row className={styles.signatureWrapper} justify='space-between' align='bottom'>
                    <Col>
                      <Row>
                        <Logo className={styles.logo} />
                      </Row>
                    </Col>
                    <Col className={styles.signatureCol}>
                      <Signature className={styles.signature} />
                      <div className={styles.signatureText}>
                        <span className={styles.signatureTitle}>
                          {t('certificateMotion.part4')}
                        </span>
                        <br />
                        {t('certificateMotion.part5')}
                        <br />
                        {t('certificateMotion.part6')}
                      </div>
                    </Col>
                    <Col>
                      <Row className={styles.qr} justify='space-around' align='middle'>
                        <Col>
                          {qr !== null && <img src={qr} className={styles.qrImg} />}
                          <div className={styles.qrText}>{t('certificateMotion.part3')}</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className={styles.footerWrapper}></div>
            <img
              className={styles.background}
              src={motionCertificateBack}
              alt='certificate-background'
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MemberCertificate;
